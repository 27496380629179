import IndexedDbHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

import type * as UserDeviceModule from 'o365.pwa.declaration.shared.dexie.objectStores.UserDevice.d.ts';

export class UserDevice implements UserDeviceModule.UserDevice {
    static objectStoreDexieSchema: string = "&deviceRef";

    deviceRef: string;
    deviceInfoString: string;

    constructor(options: UserDeviceModule.IUserDeviceOptions) {
        this.deviceRef = options.deviceRef ?? self.crypto.randomUUID();
        this.deviceInfoString = options.deviceInfoString;
    }

    public async save(): Promise<void> {
        await IndexedDbHandler.updateUserDevice(this);
    }

    public async delete(): Promise<void> {
        await IndexedDbHandler.deleteUserDevice(this);
    }
}

export default UserDevice;
